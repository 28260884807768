import { GlobalShoeBoxProvider, GlobalShoeBoxTriggerArea } from 'components/dist/organisms/GlobalShoeBox';
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { Route } from 'src/constants/routing';
import { JestTestId } from 'src/constants/tests';

import { useDashboardSidebarShoebox } from './dashboard-sidebar-shoebox.state';

interface DashboardSidebarShoeboxProps {
    size?: 'default' | 'small';
}

export const DashboardSidebarShoebox = (props: DashboardSidebarShoeboxProps) => {
    const state = useDashboardSidebarShoebox();

    return <GlobalShoeBoxProvider
        team={state.team}
        onOpenFileDoubleClick={state.onOpenFileDoubleClick}
        onClose={state.onClose}
        loadingLoans={state.isLoadingLoans}
        secureUploadPageLink={state.secureUploadPageLink}
        loadingShoeBoxFiles={state.isLoadingShoeBoxFiles}
        me={state.me}
        selectedTeamUserIds={state.selectedTeamUserIds}
        onTeamFilterChange={state.onTeamFilterChange}
        hideLoanFilter={state.hideLoanFilter}
        onGoToLoanCreatePage={state.onGoToLoanCreatePage}
        onGoToLoan={state.onGoToLoan}
        onMoveShoeBoxFilesToLoan={state.onMoveShoeBoxFilesToLoan}
        onCancelUploadingFile={state.onCancelUploadingFile}
        onAddFilesToShoeBox={state.onAddFilesToShoeBox}
        onDeleteShoeBoxFiles={state.onDeleteShoeBoxFiles}
        onAddMeToLoan={state.onAddMeToLoan}
        onOpenShoeBoxFileInNewTab={state.onOpenShoeBoxFileInNewTab}
        onRenameShoeBoxFile={state.onRenameShoeBoxFile}
        loans={state.loans}
        totalItems={state.totalItems}
        folders={state.folders} >
        <NextLinkComposed
            to={{
                pathname: Route.MY_SHOEBOX,
                query: {
                    [QUERY_PARAM_LOAN_ID]: state.queryLoanId
                }
            }}
            ref={state.setDroppableNodeRef}>
            <GlobalShoeBoxTriggerArea
                isOver={state.isDroppableNodeOver}
                data-testid={JestTestId.GlobalShoeBoxTrigger}
                size={props.size} />
        </NextLinkComposed>
    </GlobalShoeBoxProvider>
}